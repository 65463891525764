var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "axis" }, [
    _c("div", {
      staticClass: "line-x",
      style: "top: " + (_vm.y + _vm.rulerWidth) + "px"
    }),
    _c("div", {
      staticClass: "line-y",
      style: "left: " + (_vm.x + _vm.rulerWidth) + "px"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }