//
//
//
//
//
//

export default {
  props: {
    rulerWidth: {
      "default": 0 },

    x: {
      "default": 0 },

    y: {
      "default": 0 } } };